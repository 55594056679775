import { useTranslation as originalUseTranslation } from "react-i18next";
import { useRouter } from "next/router";
import enTranslations from "public/locales/en/common.json";
import koTranslations from "public/locales/ko/common.json";

const translationsMap: Record<string, Record<string, string>> = {
  en: enTranslations,
  ko: koTranslations,
};

export const useBaseTranslation = () => {
  const router = useRouter();
  const locale = router.locale || "ko";
  const translations = translationsMap[locale] || {};

  const t = (key: string) => translations[key] || key;

  return { t };
};

export const useCustomTranslation = () => {
  const customTranslation = useBaseTranslation();
  const originalTranslation = originalUseTranslation();

  return {
    t: (key: string, options?: any) =>
      options ? String(originalTranslation.t(key, options)) : customTranslation.t(key),
  };
};
